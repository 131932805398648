.closePopup {
    background-color: transparent;
    position: absolute;
    top: 12px;
    right: 20px;
    width: 20px;
    height: 20px;
}

.withLeftClose {
    top: 12px;
    left: 16px;
    right: unset;
}

.withRightClose {
    right: 16px;
    left: auto;
}

.withMarginTop {
    top: 46px;
}
